import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import {  guestLogin, setAnyTokenInSessionStorage } from './lib/api'
import { clearTabStates,  loginUserAc, updateOrgNameId } from './redux/actions/opening/action'
import { orgtokenVerify } from './service/tokenVerify'
import { Spin, Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import Deactivated from './Deactivated'
import GenerateNew from './GenerateNew'
import PageLoader from './LoadingScreen'
const { Text } = Typography

const Login = (props: { applicationId: string }) => {
  const { base64String } = useParams<{ base64String: string }>()
  const [loading, setLoading] = useState(false) // New state for loading
  const [isInvalidUser, setIsInvalidUser] = useState(false)
  const [isDeactivated, setIsDeactivated] = useState(false)
  const [isPwdExpired, setIsPwdExpired] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const performLogin = async () => {
      setLoading(true)
      dispatch(clearTabStates())
      sessionStorage.removeItem('current_tab_application_token')

      guestLogin(base64String)
        .then(async (loginResponse) => {
          setAnyTokenInSessionStorage('current_tab_application_token', loginResponse.token).then(() => {
            return true
          })
          setTimeout(() => {
            setLoading(false)
          }, 2000)
          dispatch(loginUserAc(loginResponse.token, loginResponse.user))    
          localStorage.setItem('login_token', loginResponse.token)
          let updatedOrg = {
            applicationId: loginResponse.user.registrations[0].applicationId,
            id: loginResponse.user.registrations[0].applicationId,
            applicationName: loginResponse.user.registrations[0].username,
            applicationImgUrl: loginResponse.user.registrations[0].data?.logo,
            verified: true,
            data: {
              logo: '',
              description: ''
            },
            roles: loginResponse.user.registrations[0].roles[0]
          }
          dispatch(updateOrgNameId(updatedOrg))
                                        

          history.push(`/o/${loginResponse.user.registrations[0].applicationId}/homepage`)
        })
        .catch((error) => {
          setTimeout(() => {
            setLoading(false)
          }, 2000)
          if (error.response && error.response.status === 401) {
            setAnyTokenInSessionStorage('sessionKey', base64String).then(() => {
              return true
            })
            setIsPwdExpired(true)
          } else if (error.response && error.response.status === 404) {
            setIsInvalidUser(true)
          } else if (error.response && error.response.status === 410) {
            setIsDeactivated(true)
          }
        })
    }
    if (base64String) {
      performLogin()
    } else if (orgtokenVerify() && props.applicationId) {
      history.push(`/o/${props.applicationId}/homepage`)
    } else {
      setLoading(false)
      history.push(`/page-not-found`)
    }
  }, [base64String])

  return (
    <>
      {loading ? (
        <div className='flex items-center justify-center min-h-screen bg-gray-50 p-4'>
          <div className='max-w-md w-full bg-white shadow-lg rounded-lg p-6 sm:p-8 text-center'>
            <Spin size='large' className='mb-4' />

            <h1 className='text-xl text-gray-800 mb-4'>Processing your login</h1>

            <Text className='text-gray-600 mb-4'>Please wait while we authenticate your credentials. This may take a few moments.</Text>
          </div>
        </div>
      ) : isInvalidUser ? (
        <div className='flex items-center justify-center min-h-screen bg-gray-50 p-4'>
          <div className='max-w-md w-full bg-white shadow-lg rounded-lg p-6 sm:p-8 text-center'>
            <Title level={3} className=' mb-4'>
              Authentication Failed
            </Title>
            <Text className='text-gray-600 mb-4'>
              We were unable to verify your credentials. This could be due to an expired link. Please check the link you used or{' '}
              <a href='https://support.tacitbase.com/' style={{ color: '#009D79' }}>
                contact support
              </a>{' '}
              if the problem persists.
            </Text>{' '}
          </div>
        </div>
      ) : isDeactivated ? (
        <Deactivated />
      ) : isPwdExpired ? (
        <GenerateNew />
      ) : (
        <PageLoader />
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId
})

export default connect(mapStateToProps)(Login)
